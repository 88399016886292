.ProjectGlance-container
{
    background-color: black;
}

.ProjectGlance-container .gal-img-1
{
    width: 100%;
    height: 550px;
}
.ProjectGlance-container .gal-img-2
{
    width: 100%;
    height: 425px;
}
.ProjectGlance-container .gal-img-3
{
    width: 100%;
    height: 300px;
}
.ProjectGlance-container .gal-img-4
{
    width: 100%;
    height: 425px;
}

@media(max-width:993px)
{
    .ProjectGlance-container .gal-img-1
{
    width: 100%;
    height: 277px;
}
.ProjectGlance-container .gal-img-2
{
    width: 100%;
    height: 277px;
}
.ProjectGlance-container .gal-img-3
{
    width: 100%;
    height: 277px;
}
.ProjectGlance-container .gal-img-4
{
    width: 100%;
    height: 277px;
}
}