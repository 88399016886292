.OfferCardsContainer-container
{
    
    background: #F8F8F8;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.OfferCardsContainer-container .testimonial-card
{
    cursor: pointer;
    border-radius: 5px;    
    /* background: white; */
    /* filter: drop-shadow(0 3px 20.5px rgba(0, 0, 0, 0.063)); */
}
.OfferCardsContainer-container .testimonial-card .arrow-img
{
    width:45px;
}


.OfferCardsContainer-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.OfferCardsContainer-container .profile-info
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    position: relative;
    padding-top: 12px;
}

.OfferCardsContainer-container .user-img{
    border-radius: 50%;
    width: 125px;
    transform: translateY(-30px);
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
}

.OfferCardsContainer-container .profile-info .review-text
{
    color: #cecece;
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 1.8rem;
    max-width: 80%;
    max-height: 150px;
    overflow-y: auto;
}
.OfferCardsContainer-container .testimonial-card-line-seprator
{
    border: 1px solid #939393;
    width: 80%;
    height: 0;
    
}
.OfferCardsContainer-container .profile-info .name
{
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 10px;
    text-transform: uppercase;
}

.OfferCardsContainer-container .btn-container .btn-arrow-icon
{
    width: 14px;
    height: 14px;
}
.OfferCardsContainer-container .btn-container .btn-arrow-icon-left
{
    transform: rotate(130deg);
    transition:transform .3s ;
}
.OfferCardsContainer-container .btn-container .btn-arrow-icon-left-rotate
{
    transition:transform .3s ;
    transform: rotate(180deg);
}

.OfferCardsContainer-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.OfferCardsContainer-container .text-line-details .body-paragraph
{
    font-size: 17px;
}
/* 
.OfferCardsContainer-container .swiper-slide
{
    transform: translate(50% , 25px);
    overflow-y: visible;
    display: block;
    
}
.OfferCardsContainer-container .swiper-slide-active
{
    transform: translate(-50% , 25px);
    display: block;
}
.OfferCardsContainer-container .swiper-slide-next
{
    transform: translate(0 , 0)!important;
    display: grid;
    align-items: center;
    justify-content: center;
}
.OfferCardsContainer-container .swiper-slide-next .testimonial-card
{
    width: 600px;
}

.OfferCardsContainer-container .card-list::before
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 20%;
    left: 0;
    top: 0;
    z-index: 100;
    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.406) 24%, rgba(255, 255, 255, 0.631) 46%, rgba(255, 255, 255, 0.863) 74%,rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%);
}
.OfferCardsContainer-container .card-list::after
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 20%;
    right: 0;
    top: 0;
    z-index: 100;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.406) 24%, rgba(255, 255, 255, 0.631) 46%, rgba(255, 255, 255, 0.863) 74%,rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%);

} */

.OfferCardsContainer-container .swiper-pagination
{
    /* width: fit-content!important; */
   bottom: -45px!important;
   position: relative;
}
.OfferCardsContainer-container .swiper-pagination .swiper-pagination-bullet
{
    background-color: #D4D4D4;
    opacity: 1;
    width: 13px;
    height:13px;
    
}
.OfferCardsContainer-container .swiper-pagination .swiper-pagination-bullet-active
{
    background-color: #9B9B9B;
    opacity: 1;
    width: 15px;
    height:15px;
}

.OfferCardsContainer-container .shrinked-para
{
    width: 60%;
    margin: auto;
}


.OfferCardsContainer-container .sold-container
{
    position: absolute;
    background-color: white;
    border-radius: 50%;
    /* padding: 10px; */
    height: 50px;
    width: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 3px solid #707070;
    right: 0;
    top: 0;
}



@media(max-width: 993px)
{
    .OfferCardsContainer-container .shrinked-para
    {
        width: 100%;
        margin: auto;
    }
}

@media(max-width: 750px)
{
    .OfferCardsContainer-container .swiper-slide .testimonial-card
    {
        width: 95%;
        margin: auto;
    }
}
@media(max-width: 576px)
{
    .OfferCardsContainer-container .swiper-slide .testimonial-card
    {
        width: 100%;
        margin: auto;
    }
}