
			/* h1, h2, h3, h4, h5, h6, p { margin:0pt 0pt 10pt }
			li { margin-top:0pt; margin-bottom:10pt }
			h1 { margin-top:24pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:14pt; font-weight:bold; color:#365f91 }
			h2 { margin-top:10pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:13pt; font-weight:bold; color:#4f81bd }
			h3 { margin-top:10pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:11pt; font-weight:bold; color:#4f81bd }
			h4 { margin-top:10pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:11pt; font-weight:bold; font-style:italic; color:#4f81bd }
			h5 { margin-top:10pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:11pt; font-weight:normal; color:#243f60 }
			h6 { margin-top:10pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:11pt; font-weight:normal; font-style:italic; color:#243f60 } */
            .privacy-policy-container p{font-size: 20px;}
			.privacy-policy-container .Heading7 { margin-top:10pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:11pt; font-weight:normal; font-style:italic; color:#404040 }
			.privacy-policy-container .Heading8 { margin-top:10pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:10pt; font-weight:normal; color:#4f81bd }
			.privacy-policy-container .Heading9 { margin-top:10pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:10pt; font-weight:normal; font-style:italic; color:#404040 }
			.privacy-policy-container .BodyText { margin-bottom:6pt; line-height:115%; font-size:16pt }
			.privacy-policy-container .BodyText2 { margin-bottom:6pt; line-height:200%; font-size:16pt }
			.privacy-policy-container .BodyText3 { margin-bottom:6pt; line-height:115%; font-size:8pt }
			.privacy-policy-container .Caption { margin-bottom:10pt; line-height:normal; font-size:9pt; font-weight:bold; color:#4f81bd }
			.privacy-policy-container .HorizontalLine { margin-bottom:10pt; line-height:115%; border-bottom:0.75pt solid #000000; padding-bottom:1pt; font-size:1pt }
			.privacy-policy-container .IntenseQuote { margin:10pt 46.8pt 14pt; line-height:115%; border-bottom:0.75pt solid #4f81bd; padding-bottom:4pt; font-size:11pt; font-weight:bold; font-style:italic; color:#4f81bd }
			.privacy-policy-container .List { margin-left:18pt; margin-bottom:10pt; text-indent:-18pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .List2 { margin-left:36pt; margin-bottom:10pt; text-indent:-18pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .List3 { margin-left:54pt; margin-bottom:10pt; text-indent:-18pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .ListBullet { margin-left:18pt; margin-bottom:20pt; text-indent:-18pt; line-height:115%; font-size:18pt }
			.privacy-policy-container .ListBullet2 { margin-left:36pt; margin-bottom:20pt; text-indent:-18pt; line-height:115%; font-size:19pt }
			.privacy-policy-container .ListBullet3 { margin-left:54pt; margin-bottom:20pt; text-indent:-18pt; line-height:115%; font-size:18pt }
			.privacy-policy-container .ListContinue { margin-left:18pt; margin-bottom:6pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .ListContinue2 { margin-left:36pt; margin-bottom:6pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .ListContinue3 { margin-left:54pt; margin-bottom:6pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .ListNumber { margin-left:18pt; margin-bottom:10pt; text-indent:-18pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .ListNumber2 { margin-left:36pt; margin-bottom:10pt; text-indent:-18pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .ListNumber3 { margin-left:54pt; margin-bottom:10pt; text-indent:-18pt; line-height:115%; font-size:11pt }
			.privacy-policy-container .ListParagraph { margin-left:36pt; margin-bottom:10pt; line-height:115%; font-size:15pt }
			.privacy-policy-container .Macro { margin-bottom:10pt; line-height:115%; font-size:10pt }
			.privacy-policy-container .NoSpacing { margin-bottom:0pt; line-height:normal; font-size:11pt }
			.privacy-policy-container .Quote { margin-bottom:10pt; line-height:115%; font-size:11pt; font-style:italic; color:#000000 }
			.privacy-policy-container .Subtitle { margin-bottom:10pt; line-height:115%;   font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#4f81bd }
			.privacy-policy-container .TOCHeading { margin-top:24pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:115%;   font-size:14pt; font-weight:bold; color:#365f91 }
			.privacy-policy-container .Title { margin-bottom:15pt; line-height:normal; border-bottom:1pt solid #4f81bd; padding-bottom:4pt;   font-size:26pt; letter-spacing:0.25pt; color:#17365d }
			.privacy-policy-container span.BodyText3Char { font-size:8pt }
			.privacy-policy-container span.BookTitle { font-weight:bold; font-variant:small-caps; letter-spacing:0.25pt }
			.privacy-policy-container span.Emphasis { font-style:italic }
			.privacy-policy-container span.Heading1Char {   font-size:14pt; font-weight:bold; color:#365f91 }
			.privacy-policy-container span.Heading2Char {   font-size:13pt; font-weight:bold; color:#4f81bd }
			.privacy-policy-container span.Heading3Char {   font-weight:bold; color:#4f81bd }
			.privacy-policy-container span.Heading4Char {   font-weight:bold; font-style:italic; color:#4f81bd }
			.privacy-policy-container span.Heading5Char {   color:#243f60 }
			.privacy-policy-container span.Heading6Char {   font-style:italic; color:#243f60 }
			.privacy-policy-container span.Heading7Char {   font-style:italic; color:#404040 }
			.privacy-policy-container span.Heading8Char {   font-size:10pt; color:#4f81bd }
			.privacy-policy-container span.Heading9Char {   font-size:10pt; font-style:italic; color:#404040 }
			.privacy-policy-container span.Hyperlink { text-decoration:underline; color:#0000ff }
			.privacy-policy-container span.IntenseEmphasis { font-weight:bold; font-style:italic; color:#4f81bd }
			.privacy-policy-container span.IntenseQuoteChar { font-weight:bold; font-style:italic; color:#4f81bd }
			.privacy-policy-container span.IntenseReference { font-weight:bold; font-variant:small-caps; text-decoration:underline; letter-spacing:0.25pt; color:#c0504d }
			.privacy-policy-container span.MacroTextChar {  font-size:10pt }
			.privacy-policy-container span.QuoteChar { font-style:italic; color:#000000 }
			.privacy-policy-container span.Strong { font-weight:bold }
			.privacy-policy-container span.SubtitleChar {   font-size:12pt; font-style:italic; letter-spacing:0.75pt; color:#4f81bd }
			.privacy-policy-container span.SubtleEmphasis { font-style:italic; color:#808080 }
			.privacy-policy-container span.SubtleReference { font-variant:small-caps; text-decoration:underline; color:#c0504d }
			.privacy-policy-container span.TitleChar {   font-size:26pt; letter-spacing:0.25pt; color:#17365d }
			.privacy-policy-container span.\31 { text-decoration:underline; color:#0000ff }
@media (max-width: 900px) { 
/* img { 
   max-width: 100%;
   height: auto;
} */

.privacy-policy-container .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.privacy-policy-container table {
    width: 100%;
    border-collapse: collapse;
}

.privacy-policy-container td, th {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}
}