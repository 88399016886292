.blog-container
{
    background: #F8F8F8;
    box-sizing: border-box;

}

.blog-container .container
{
    /* background-image: linear-gradient(to top, rgba(151, 151, 151, 0.151), rgba(255, 255, 255, 0)); */
    padding-bottom: 15px;

}

.blog-container .card-box
{
    display: flex;
    background: white;
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.09));
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    gap: 10px;
    height: 100%;
    padding: 15px;
    cursor: pointer;
}

.blog-container .swiper-slide{
    height: auto;
}

.blog-container .card-box .card-text-content
{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: auto;
}

.blog-container .card-box .blog-info
{
    width: 90%;
}

.blog-container .card-box .title{
    font-size: 20px;
    color: #1e1e1e;
    font-weight: 700;
    font-family: "Lora"!important;
    text-align: center;
}
.blog-container .card-box .author{
    font-size: 16px;
    color: #e0e2e6;
    font-weight: 500;
}
.blog-container .card-box .pb-date{
    color :#e0e2e6;
    font-size: .78rem;
    font-weight: 500;
}
.blog-container .card-box .line-seprator{
    border: 1px solid grey;
    width: 90%;
    height: 0;
}

.blog-container .card-box .admin-icon
{
    height: 14px;
    width: 15px;
    margin-bottom: 4px;
}
.blog-container .card-box .date-icon
{
    height: 14px;
    width: 15px;
}

.blog-container .card-box a
{
    text-decoration: none;
    font-size: 16px;
    color: #39b4e4;
    font-weight: 500;
    transition: .3s;
}


.blog-container .card-box a:hover{
    color: #1e83ab;
    cursor: pointer;
}

.blog-container .card-box img{
    width: 100%;
    margin-bottom: 10px;
}

.blog-container .card-box .btn-icon
{
    width: 12px;
    height: 12px;
    margin-bottom: 0;
    margin-left: 3px;
}

.blog-container .card-box .top-links
{
    display: flex;
    justify-content: start;
    gap: 15px;
}

.blog-container .card-box .top-links img{
    width: 15px;
}
.blog-container .card-box .top-links p{
    font-size: .8rem;

}

.blog-container .blog-text-content
{
}

.blog-container .blog-upper-content
{
}

.blog-container .blog-upper-content .blog-text-content
{
    
}
.blog-container .blog-btn
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.blog-container .blog-btn .swiper-button
{
    border-radius: 50%;
    border: none;
    background-color: white;
    border: 1px solid white;
    padding: 10px 20px;
    color: purple;
    box-shadow:0 3px 8px 0 RGBA(0,0,0,0.16 );
    transition: .2s;
}
.blog-container .blog-btn .swiper-button:hover
{
    border: 1px solid #8740A5;
    box-shadow:0 0px 0px 0 RGBA(0,0,0,0.16 );
}

.blog-container .card-list
{
    /* margin: auto;
    width: 90%; */
}

.blog-container .btn-arrow-icon
{
    width: 14px;
    height: 14px;
}
.blog-container .btn-arrow-icon-left
{
    transform: rotate(130deg);
    transition:transform .3s;
}
.blog-container .btn-arrow-icon-left-rotate
{
    transition:transform .3s;
    transform: rotate(180deg);
}

.blog-container .swiper-slide-next
{
    padding-top: 30px!important;
}
.blog-container .swiper-slide
{
    padding-top: 0;
}

@media(max-width:993px)
{
    .blog-container .swiper-slide-next
    {
        padding-top: 0px!important;
    }
}