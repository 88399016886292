.Testimonials-container
{
    
    /* background: linear-gradient(90deg, rgba(252,243,255,1) 0%, rgba(243,243,243,1) 100%); */
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.Testimonials-container .testimonial-card
{
    cursor: pointer;
    border-radius: 5px;    
    background: white;
    filter: drop-shadow(0 3px 20.5px rgba(0, 0, 0, 0.063));
}
.Testimonials-container .testimonial-card .arrow-img
{
    width:45px;
}


.Testimonials-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.Testimonials-container .profile-info
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Testimonials-container .user-img{
    border-radius: 50%;
    width: 125px;
    transform: translateY(-30px);
    filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.16));
}

.Testimonials-container .profile-info .review-text
{
    color: #cecece;
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 1.8rem;
    max-width: 80%;
    max-height: 150px;
    overflow-y: auto;
}
.Testimonials-container .testimonial-card-line-seprator
{
    border: 1px solid #939393;
    width: 80%;
    height: 0;
    
}
.Testimonials-container .profile-info .name
{
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 10px;
    text-transform: uppercase;
}

.Testimonials-container .btn-container .btn-arrow-icon
{
    width: 14px;
    height: 14px;
}
.Testimonials-container .btn-container .btn-arrow-icon-left
{
    transform: rotate(130deg);
    transition:transform .3s ;
}
.Testimonials-container .btn-container .btn-arrow-icon-left-rotate
{
    transition:transform .3s ;
    transform: rotate(180deg);
}

.Testimonials-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.Testimonials-container .swiper-slide
{
    transform: translate(50% , 25px);
    overflow-y: visible;
    display: block;
    
}
.Testimonials-container .swiper-slide-active
{
    transform: translate(-50% , 25px);
    display: block;
}
.Testimonials-container .swiper-slide-next
{
    transform: translate(0 , 0)!important;
    display: grid;
    align-items: center;
    justify-content: center;
}
.Testimonials-container .swiper-slide-next .testimonial-card
{
    width: 600px;
}

.Testimonials-container .card-list::before
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 20%;
    left: 0;
    top: 0;
    z-index: 100;
    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.406) 24%, rgba(255, 255, 255, 0.631) 46%, rgba(255, 255, 255, 0.863) 74%,rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%);
}
.Testimonials-container .card-list::after
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 20%;
    right: 0;
    top: 0;
    z-index: 100;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.406) 24%, rgba(255, 255, 255, 0.631) 46%, rgba(255, 255, 255, 0.863) 74%,rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%);

}

.Testimonials-container .swiper-pagination
{
    /* width: fit-content!important; */
   bottom: -45px!important;
   position: relative;
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet
{
    background-color: #D4D4D4;
    opacity: 1;
    width: 13px;
    height:13px;
    
}
.Testimonials-container .swiper-pagination .swiper-pagination-bullet-active
{
    background-color: #9B9B9B;
    opacity: 1;
    width: 15px;
    height:15px;
}

@media(max-width:1450px)
{
    .Testimonials-container .swiper-slide-active
    {
        transform: translate(0 , 0)!important;
    }
    .Testimonials-container .swiper-slide-next
    {
        transform: translate(0 , 0)!important;
    }
    .Testimonials-container .swiper-slide
    {
        transform: translate(0 , 0)!important;
    }
    .Testimonials-container .swiper-slide-next .testimonial-card
    {
        width: auto;
    }
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 80%;
        margin: auto;
    }
    .Testimonials-container .swiper{
        padding-left: 10px;
        padding-right: 10px;
    }
    .Testimonials-container .profile-info .review-text
    {
        max-height: 220px;
    }
}

@media (min-width: 1850px) {

    .Testimonials-container .swiper-slide
    {
        /* transform: translate(40% , 25px); */
        overflow-y: visible;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .Testimonials-container .swiper-slide-active
    {
        /* transform: translate(-50% , 25px); */
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Testimonials-container .swiper-slide-next
    {
        transform: translate(0 , 0)!important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Testimonials-container .swiper-slide-next .testimonial-card,
    .Testimonials-container .swiper-slide-active .testimonial-card,
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 600px;
        margin: 0 -50%;
    }
}

@media(max-width: 750px)
{
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 95%;
        margin: auto;
    }
}
@media(max-width: 576px)
{
    .Testimonials-container .swiper-slide .testimonial-card
    {
        width: 100%;
        margin: auto;
    }
}