.InteriorDesign-container
{

}

.InteriorDesign-container .img-container
{
    height: 580px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.InteriorDesign-container .title
{
    font-weight: 300!important;
    font-size: 2rem;
}
.InteriorDesign-container .content
{
    font-weight: 300!important;
}

@media(max-width:993px)
{
    .InteriorDesign-container .content
    {
        width: 100% !important;
    }
}

@media(max-width:769px)
{
    .InteriorDesign-container .img-container
    {
        height: 400px;

    }
}