.BlogInnerHeroBanner-container
{
    background-color: #040404;
    height: auto;
    min-height: 60vh;
}

.BlogInnerHeroBanner-container .main-heading,
.BlogInnerHeroBanner-container .main-heading span
{
    letter-spacing: 1px;
    font-size: 80px;
}

.BlogInnerHeroBanner-container .overlay
{
    background-color: #f0f2f5be;
}

.BlogInnerHeroBanner-container .about-hero-img
{
    height: 15rem;
}

.BlogInnerHeroBanner-container .main-heading
{
    position: relative;
}

.BlogInnerHeroBanner-container .main-heading .txt-1{
    position: relative;
    /* top: -80px; */
    left: 12%;
    z-index: 1;

}
.BlogInnerHeroBanner-container .main-heading .about-hero-img{
  position: relative;
    z-index: 2;
}

.BlogInnerHeroBanner-container .main-heading .txt-2{
    position: relative;
    left: -5%;
    z-index: 3;
}

.BlogInnerHeroBanner-container .hero-section-heading
{
    width: 85%;
    margin: auto;
}

.BlogInnerHeroBanner-container .blog-details-container
{
    gap: 10px;
}
.BlogInnerHeroBanner-container .blog-details-container p
{
    color: #EAEBEB;
}

@media(max-width:1340px)
{
    .BlogInnerHeroBanner-container .main-heading,
    .BlogInnerHeroBanner-container .main-heading span
    {
        letter-spacing: 1px;
        font-size: 80px;
    }
}

@media(max-width:1000px)
{
    .BlogInnerHeroBanner-container .main-heading,
    .BlogInnerHeroBanner-container .main-heading span
    {
        font-size: 5px;
    }
    .BlogInnerHeroBanner-container .main-heading .txt-1{
        top: 0;
        left: 0;
    
    }
    
    .BlogInnerHeroBanner-container .main-heading .txt-2{
        left: 0;
    }
    .BlogInnerHeroBanner-container
    {
        min-height: 100vh;
    }
    .BlogInnerHeroBanner-container .about-hero-img
    {
        height: 20rem;
    }
    .BlogInnerHeroBanner-container .hero-section-heading
    {
        width: 100%;
        margin: auto;
    }
}

@media(min-width:1774px)
{
    .BlogInnerHeroBanner-container .main-heading,
    .BlogInnerHeroBanner-container .main-heading span
    {
        letter-spacing: 2px;
        font-size: 90px;

    }
    .BlogInnerHeroBanner-container .main-heading .txt-1{
        /* top: -80px; */
        left: 8%;
        z-index: 1;
    
    }
    .BlogInnerHeroBanner-container .main-heading .about-hero-img{
      position: relative;
        z-index: 2;
        height: 20rem;
        left: -6%;
    }
    
    .BlogInnerHeroBanner-container .main-heading .txt-2{
        position: relative;
        left: -14%;
        z-index: 3;
    }
}