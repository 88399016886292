.SaleRegisterForm-container
{
    /* transform: translateY(-100px); */

}
.SaleRegisterForm-container .container{
    background-color: #FFFFFF;
    
    filter: drop-shadow(0px 3px 15px rgba(0,0,0,0.16));
    }
    
    .SaleRegisterForm-container .main-heading
    {
        font-size: 55px;
    }

    
.SaleRegisterForm-container .mail-img-container
{
    background-color: #FFFFFF;
    border-radius: 50%;
   padding:30px 20px;
   margin-right: 20px;
}
.SaleRegisterForm-container .bell-icon
{
    width: 15px;
}
.SaleRegisterForm-container .mail-img
{
    
}

.SaleRegisterForm-container .form-container .inp-container
{
    background:#FFFFFF;
    border: 1px solid rgb(91, 91, 91);
    padding-left:5px;
    width:100%;
    margin: auto;
    overflow: hidden;
}
.SaleRegisterForm-container .form-container .inp-container input{
    width:89%;
    border: none;
    padding:25px 20px;
    padding-left: 19px;
    color: rgb(8, 8, 8);
    background:transparent;

}
.SaleRegisterForm-container .form-container .inp-container input::placeholder{
    color: rgb(73, 73, 73);
}
.SaleRegisterForm-container .form-container .inp-container input:focus{
    outline: none;
}
.SaleRegisterForm-container .form-container .inp-container .inp-btn
{
    padding: 25px 23px;
    border: none;
    color: white;
    background-color: #232323;
    transition: .3s;
}
.SaleRegisterForm-container .form-container .inp-container .inp-btn:hover
{
    color: white;
    background-color: black;
}

@media(max-width:993px) {
    .SaleRegisterForm-container .main-heading
    {
        font-size: 40px;
    }
}