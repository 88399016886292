.GoogleRatingSection-container
{

}
.GoogleRatingSection-container .container
{

    background: white;
    filter: drop-shadow(0 3px 20.5px rgba(0, 0, 0, 0.063));
}

.GoogleRatingSection-container .google-review-icon
{
    width: 150px;
}
.GoogleRatingSection-container .star-icons
{
    width: 80px;
}

.GoogleRatingSection-container .user-img
{
    width: 60px;
}

.GoogleRatingSection-container .clientName-text
{
    color: black;
    font-weight: bold;
}

.GoogleRatingSection-container .testimonial-card
{
    cursor: pointer;
    border-radius: 5px;    
}



.GoogleRatingSection-container .review-text-main
{
    max-height: 200px;
    overflow-y: auto;
}
.GoogleRatingSection-container .review-text-main::-webkit-scrollbar {
    width: 5px;
  }

.GoogleRatingSection-container .img-placeholder
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.GoogleRatingSection-container .img-placeholder h3
{
    color:white;
}