.news-container
{
    background-color: black;
}


.news-container .box-1
{
    transform: translateY(60px);
}

.news-container .page-btn-container .genral-btn
{
    width: 200px;
}

@media(max-width:992px)
{
    .news-container .box-1
{
    transform: translateY(0px);
}
}