.WorkContainer-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F3EF;
    
}

.WorkContainer-container .testimonial-card
{
    cursor: pointer;
}
.WorkContainer-container .testimonial-card .arrow-img
{
    width:45px;
}


.WorkContainer-container .testimonial-card .testimonial-card-info
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}


.WorkContainer-container .profile-info
{
    
}


.WorkContainer-container .testimonial-card-line-seprator
{
    border: 1px solid #c8c8c8;
    width: 100%;
    height: 0;
    
}
.WorkContainer-container .profile-info .name
{
    color: #141414;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
}

.WorkContainer-container .btn-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}


.WorkContainer-container .swiper-pagination
{
    /* width: fit-content!important; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.WorkContainer-container .swiper-pagination .swiper-pagination-bullet
{
    background-color: #D7D7D7;
    width: 8px;
    height: 8px;
    opacity: 1;
}
.WorkContainer-container .swiper-pagination .swiper-pagination-bullet-active
{
    
    background-color: #B2B2B2;
    width: 14px;
    height: 14px;
    opacity: 1;
}

.WorkContainer-container .swiper-slide-next .sec
{
    height: 430px!important;
}
.WorkContainer-container .swiper-wrapper
{
    align-items: center!important;
}

.WorkContainer-container .testimonials-list .sec
{
    width: 400px;
    height: 365px;
    cursor: pointer;
}


.WorkContainer-container .card-list::before
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 40%;
    left: 0;
    z-index: 100;
    background-image: linear-gradient(270deg, rgba(245, 243, 239,0) 0%, rgba(245, 243, 239, 0.406) 24%, rgba(245, 243, 239, 0.631) 46%, rgba(245, 243, 239, 0.863) 74%,rgba(245, 243, 239, 1) 90%, rgb(245, 243, 239) 100%);
}
.WorkContainer-container .card-list::after
{
    content: " ";
    position: absolute;
    height: 100%;
    width: 40%;
    right: 0;
    z-index: 100;
    background-image: linear-gradient(90deg, rgba(245, 243, 239,0) 0%, rgba(245, 243, 239,0.406) 24%, rgba(245, 243, 239,0.631) 46%, rgba(245, 243, 239,0.863) 74%,rgba(245, 243, 239, 1) 90%, rgba(245, 243, 239,0.9999999999999999) 100%);

}

.WorkContainer-container .swiper-slide
{
    display: flex;
    justify-content: center;
}

.WorkContainer-container .shrinked-para
{
    width: 70%;
    margin: auto;
}


.WorkContainer-container  .swiper-button-prev
{
    position: absolute;
    bottom: 45%;
    left: 0%;
    z-index: 200;
    cursor: pointer;
    padding-right: 10px;
    padding-top: 30px;
}
.WorkContainer-container .swiper-button-next
{
    position: absolute;
    bottom: 45%;
    z-index:200;
    right: 0%;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 30px;

}
.swiper-button-prev-outer
{
    position: absolute;
    bottom: 35%;
    left: 0%;
    z-index: 200;
    cursor: pointer;
    padding-right: 10px;
    padding-top: 30px;
}
.swiper-button-next-outer
{
    position: absolute;
    bottom: 35%;
    z-index:1000;
    right: 0%;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 30px;

}


@media(max-width: 1000px)
{
.WorkContainer-container .testimonials-list .sec
{
    width: 350px;
    height: 340px;
}
.WorkContainer-container .swiper-slide-next .sec
{
    height: 340px!important;
}
.WorkContainer-container .card-list::before
{
    content: none;
}
.WorkContainer-container .card-list::after
{
    content: none;
}

}


@media(max-width:993px)
{
    .WorkContainer-container .shrinked-para
    {
        width: 100%;
        margin: auto;
    }
    .WorkContainer-container  .swiper-button-prev-blog
    {
        left: 20%;
    }
    .WorkContainer-container .swiper-button-next-blog
    {
        right: 20%;
    
    }

.swiper-button-next-outer,
.swiper-button-prev-outer
{
   display: none;

}
}