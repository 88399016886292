.contact_section {
 background: white;
}

.contact_section .contact_form_form_container input,
.contact_section .contact_form_form_container textarea
{
  border: none;
  border:1px solid #ABABAB;
  padding:15px 20px;
  color: black;
}

.contact_section .contact-details .body-heading
{
  font-size: 45px;
}

.contact_section .contact-details .para_main
{
  font-size: 1.5rem;
}

.contact_section h4,
.contact_section h2,
.contact_section p {
  color: black;
  align-self: flex-start;
  text-align: start;
}
.contact_section .contact-details {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  flex-direction: column;
}

.contact_section .genral-btn-light
{
  width: 100%;
}
.contact_section .contact-details h4 {
  font-size: 16px;
}

/* Styling for input fields */
.contact_section input[type="text"],
.contact_section input[type="tel"],
.contact_section input[type="email"],
.contact_section textarea {
  
  padding: 10px 15px;
  font-size: 16px;
  color: black;
  background: transparent;
  width: 100%;
}

/* Styling for input placeholders */
.contact_section input[type="text"]::placeholder,
.contact_section input[type="tel"]::placeholder,
.contact_section input[type="email"]::placeholder,
.contact_section textarea::placeholder {
  color: rgba(90, 90, 90, 0.811);
  font-size: small;
  font-family: "Poppins", sans-serif;
}

.contact_section textarea 
{
  resize: none;
  height:150px ;

}

/* Remove default styling for focus */
.contact_section input:focus,
.contact_section textarea:focus,
.contact_section select:focus,
.contact_section textarea:focus
 {
  outline: none;
}

.contact_section .button_col {
  display: flex;
  justify-content: center;
  align-items: center;
}

