@font-face {
  font-family: 'parafont';
  src: url('../public/para-text.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Autography';
  src: url('../public/Autography.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Aervish;
  src: url(../public/Aervish.ttf);
}
* {
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth; 
  font-family: "parafont", sans-serif;
}


.font-Aervish
{
  font-family: "Aervish", sans-serif!important;
 
}

.font-Autography
{
  font-family: "Autography", sans-serif!important;
 
}
.font-para
{
  font-family: "parafont", sans-serif!important;
 
}
.font-Basker
{
  font-family: "Baskervville", serif!important;
}


.color-dark
{
  color: #252525!important;
}
.color-light
{
  color: white!important;
}

.standard-margin-space {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.standard-padding-space {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.hero-section-heading { 
  /* font-weight: 700; */
  text-transform: uppercase;
  /* font-family: 'Noto Sans', sans-serif; */
}
.hero-section-heading span{ 
  /* font-weight: 700; */
  text-transform: uppercase;
  /* font-family: 'Noto Sans', sans-serif; */
}


.stylish-btn-text{
  color:black;
  font-weight: 600;
  letter-spacing: 6.24px;
  cursor: pointer;
}
.stylish-btn-text img{
   transform: translateX(-10px);
   margin: 0;
   padding-bottom: 3px;
}

.body-heading {
  font-size: 50px;
  color: #1e1e1e;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Baskervville", serif!important;
  }

.hero-section-heading  
{ 
  font-size: 65px;
  color: #ffffff;
  font-weight:300;
  font-family: "Baskervville", serif!important;
}
.hero-section-heading-small
{
  font-size: 29px;
  color: #ffffff;
  font-weight: 400;
}
.hero-section-heading span {font-family: "Baskervville", serif!important;}
  
.body-paragraph { 
  font-size: 20px;
  color: #818181;
  font-weight:normal;
  line-height: 1.9;
  font-family: "parafont", sans-serif!important;
}

.hero-paragraph
{
  font-size: 20px;
  color: #ffffff;
  font-weight: 300;
  font-family: "parafont", sans-serif!important;
}

.hero-genral-btn
{
  padding: .8rem 2rem;
  border: 1px solid white;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
  font-family: "parafont", sans-serif!important;
  background: transparent;

  transition:.3s;
}
.hero-genral-btn:hover
{
  
  background: white;
  color: black;

}
  

.genral-btn{ 
  padding: .8rem 2rem;
  border: 1px solid white;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
  font-family: "parafont", sans-serif!important;
  background: transparent;

  transition:.3s;
}
.genral-btn:hover{ 
  background: white;
  color: black;

}
.genral-btn-light{ 
  
  padding: .8rem 2rem;
  border: 1px solid black;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: black;
  font-weight: 400;
  font-family: "parafont", sans-serif!important;
  background: transparent;

  transition:.3s;
}
.genral-btn-light:hover{ 
  background: black;
  color: white;

}

.hero-sub-heading
{
  font-size: 23px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  font-family: "Baskervville", serif!important;
}
.sub-heading
{
  font-size: 25px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: "Baskervville", serif!important;

}

.genral-btn span {
  margin-right: .4rem;
}

@media (max-width: 1250px) {
  .body-heading  {
    font-size: 37px;
  }

  .hero-section-heading {
    font-size: 45px;
  }
}

@media(max-width:993px)
{
  .stylish-btn-text{
    
    letter-spacing: 1px;
  }
}

@media (max-width: 460px) {
  .body-heading  {
    font-size: 30px;
  }
 
  .hero-section-heading  {
    font-size: 40px;
  }

  .body-paragraph {
    
  line-height: 1.4;
    font-size: 19px;
  }
}

@media (max-width: 380px) {
  .body-heading  {
    font-size: 27px;
  }

  .hero-section-heading  {
    font-size: 32px;
  }

  .body-paragraph {
    font-size: 17px;
  }

  .sub-heading {  
    /* font-size: 12px;  */
  } 
}


/* Width, color, and radius for the track */
::-webkit-scrollbar {
  width: 12px;
}

::-moz-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  transition: .3s;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #2a2a2a;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2a2a2a;
}

::-moz-scrollbar-thumb:hover {
  background: #2a2a2a;
}

/* Track */
::-webkit-scrollbar-track {
  transition: .3s;
  background: #e6e6e6;
}

::-moz-scrollbar-track {
  transition: .3s;
  background: #e6e6e6;
}
/* Track on hover */
::-webkit-scrollbar-track:hover {
  transition: .3s;
  background: #e6e6e6;
}

::-moz-scrollbar-track:hover {
  transition: .3s;
  background: #e6e6e6;
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background: #2a2a2a;
}

::-moz-scrollbar-thumb:active {
  background: #2a2a2a;
}

/* Track when dragging */
::-webkit-scrollbar-track:active {
  transition: .3s;
  background: #e6e6e6;
}

::-moz-scrollbar-track:active {
  transition: .3s;
  background: #e6e6e6;
}
