.news_card_new .blog_list_logo
{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    margin-top: auto;
}
.news_card_new
{
    padding: 0;
    width: 100%;
    margin: auto;
    cursor: pointer;
    color: white;
    transition: .3s;
}

.news_card_new:hover
{
    /* padding:0 5px; */
}

.news_card_new .blog_list_logo_img
{
    width: 40px;
}

.news_card_new .blog_list_logo_title
{
    font-size: .8rem;
    font-weight: 600;
    color: #2D2D2D;
}

.news_card_new .blog_list_logo_date
{
    font-size: .7rem;
    color: #AFAFAF;
}

.news_card_new .sub_text
{
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    letter-spacing: 2.80px;
    color: #DB831C;
}
.news_card_new .sub-heading
{
    font-size: 14px;
}
.news_card_new .card_text
{
    min-height: 180px;
}

.news_card_new .card_img
{
    width: 100%;
    height: 240px;
}
.news_card_new .card_title
{
    font-size: 1.2rem; 
    font-weight:600;
    /* color:#2D2D2D; */

}

.news_card_new .line-seprator
{
    border: 1px solid #818181;
    width: 90%;
    height: 0;
}

@media(max-width:1000px)
{
    .news_card_new{
        width: 80%;
        margin: auto;
    }
}