.BlogContent-container
{
   
}


.BlogContent-container .card-section
{
    
    filter: drop-shadow(0px 3px 13px rgba(0,0,0,0.16 ));

    background: #ffffff;
    padding:100px 40px;
}

.BlogContent-container .blog-content
{
    height: 600px;
    overflow-y: scroll;
}
.BlogContent-container .blog-content::-webkit-scrollbar {
    display: none;
}


.BlogContent-container .blog-content {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.BlogContent-container .blog-content a {
    color: #3a3a3a;
}

.BlogContent-container .card-section .title
{
    font-size: 28px;
    font-weight: 300;
}

.BlogContent-container .card-section .content{
    width: 80%;
    margin: auto;
}


@media(max-width:1400px)
{
    .BlogContent-container .card-section
    {
        padding:80px 30px;
    }
}
@media(max-width:1000px)
{
    .BlogContent-container .card-section
    {
        padding:60px 20px;
    }
}