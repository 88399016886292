.ContactPageContactDetails-container
{
    background-color: #F5F3EF;
}

.ContactPageContactDetails-container .details-anchor:hover
{
    color:rgb(224, 199, 192);
}

.ContactPageContactDetails-container .container{
    
}