.PlainCenterText-container
{
    background-size: cover;
    background-repeat: no-repeat;
}

.PlainCenterText-container .shrinked-heading
{
    width: 50%;
    margin: auto;
    line-height: 1.5;
}
.PlainCenterText-container .shrinked-heading-70
{
    width: 70%;
    margin: auto;
    line-height: 1.5;
}


@media(max-width:993px)
{
    
    .PlainCenterText-container .shrinked-heading,
    .PlainCenterText-container .shrinked-heading-70
    {
        width: 100%;
        margin: auto;
    }
}